<template>
  <div class="Awarp">
    <dashCard
        class="powerBox19"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox19_analyze','电度电费分析', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <div
              class="type-group"
              v-if="$store.state.currentSelectDeptInfo.DEPTTYPE === '站点'"
          >
            <span
                class="pick-type"
                v-for="(item, idx) in timetType"
                :key="idx"
                @click="changeType(item.code)"
            >
              <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                  item.name
                }}</font>
            </span>
          </div>
          <div class="type-group" v-else>
            <span
                class="pick-type"
                v-for="(item, idx) in timetType2"
                :key="idx"
                @click="changeType(item.code)"
            >
              <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                  item.name
                }}</font>
            </span>
          </div>
        </div>
        <a-date-picker
            v-if="searchObj.TYPE == '按天'"
            :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-week-picker
            v-if="searchObj.TYPE == '按周'"
            :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-month-picker
            v-if="searchObj.TYPE == '按月'"
            :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-date-picker
            v-if="searchObj.TYPE == '按年'"
            :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
            mode="year"
            :open="panelOpen"
            @openChange="openChange"
            @panelChange="panelChange"
            format="YYYY"
        />
      </template>
      <div class="card-content1">
        <div class="content1-line">
          <div v-if="newarr1[1].value != 0">
            <span>{{ newarr1[1].name }}</span>
            <span
            >{{
                toThousandsSeparator(newarr1[1].value)
              }}<font>{{ newarr1[1].unit }}</font></span
            >
          </div>
          <div v-if="newarr1[3].value != 0">
            <span>{{ newarr1[3].name }}</span>
            <span
            >{{
                toThousandsSeparator(newarr1[3].value)
              }}<font>{{ newarr1[3].unit }}</font></span
            >
          </div>
          <div v-if="newarr1[0].value != 0">
            <span>{{ newarr1[0].name }}</span>
            <span
            >{{
                toThousandsSeparator(newarr1[0].value)
              }}<font>{{ newarr1[0].unit }}</font></span
            >
          </div>
          <div v-if="newarr1[2].value != 0">
            <span>{{ newarr1[2].name }}</span>
            <span
            >{{
                toThousandsSeparator(newarr1[2].value)
              }}<font>{{ newarr1[2].unit }}</font></span
            >
          </div>
        </div>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <powerBox19
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerBox19",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6};
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },

        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      timetType2: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },

        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "1AT1_进线柜",
          name: "1AT1_进线柜",
        },
        {
          code: "1AT2_进线柜",
          name: "1AT2_进线柜",
        },
        {
          code: "1AT3_进线柜",
          name: "1AT3_进线柜",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "1AT1_进线柜", //选中的项目
      },
      newarr1: [{name: "", value: 0}, {name: "", value: 0}, {name: "", value: 0}, {name: "", value: 0}],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      gridLeft: [],
      colorList: ["#17A735", "#3366FF", "#F5A623", "#686868"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/degree_analysis",
        method: "post",
        data,
      })
          .then((res) => {
            this.detailInfo = {};
            let result = res.data.data;
            //console.log(result);
            var chart1 = {
              categoryData: result.categoryData,
              seriesData: result.seriesData,
            };
            this.detailInfo.chart1 = chart1;
            let newarr = [];

            for (var i = 0; i < this.detailInfo.chart1.seriesData.length; i++) {
              for (var j = 0; j < this.detailInfo.chart1.seriesData[i].value.length; j++) {
                if (this.detailInfo.chart1.seriesData[i].value[j] == 0) {
                  this.detailInfo.chart1.seriesData[i].value[j] = null;
                  newarr.push(this.detailInfo.chart1.seriesData[i].value[j]);
                }
              }
            }

            if (this.getNull(newarr) == newarr.length) {
              this.gridLeft = [0, 0, 0, 45];
            } else {
              this.gridLeft = [0, 0, 0, 5];
            }
            this.detailInfo.level = result.level.toString();
            this.detailInfo.tip = result.tip.toString();
            this.detailInfo.valley = result.valley.toString();
            this.detailInfo.peak = result.peak.toString();
            let arr = [this.commonLangHandler('powerBox19_common','平', this.getZEdata),
                    this.commonLangHandler('powerBox19_spike','尖峰', this.getZEdata),
                    this.commonLangHandler('powerBox19_valley','谷', this.getZEdata),
                    this.commonLangHandler('powerBox19_peak','峰', this.getZEdata)];
            this.newarr1 = [];
            for (const i in this.detailInfo) {
              if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
                const element = this.detailInfo[i];
                //console.log(element);
                if (typeof element == "string") {
                  if (element >= 100000000000) {
                    this.newarr1.push({
                      value: element.substring(0, 5) / 10,
                      unit: "亿",
                    });
                  } else if (element >= 10000000000) {
                    this.newarr1.push({
                      value: element.substring(0, 4) / 10,
                      unit: "亿",
                    });
                  } else if (element >= 1000000000) {
                    this.newarr1.push({
                      value: element.substring(0, 3) / 10,
                      unit: "亿",
                    });
                  } else if (element >= 100000000) {
                    this.newarr1.push({
                      value: element.substring(0, 2) / 10,
                      unit: "亿",
                    });
                  } else if (element >= 10000000) {
                    this.newarr1.push({
                      value: element.substring(0, 4),
                      unit: "万",
                    });
                  } else if (element >= 1000000) {
                    this.newarr1.push({
                      value: element.substring(0, 5) / 100,
                      unit: this.LangHandler('unitwan','万'),
                    });
                  } else if (element >= 100000) {
                    this.newarr1.push({
                      value: element.substring(0, 4) / 100,
                      unit: this.LangHandler('unitwan','万'),
                    });
                  } else if (element >= 10000) {
                    this.newarr1.push({
                      value: element.substring(0, 3) / 100,
                      unit: this.LangHandler('unitwan','万'),
                    });
                  } else if (element >= 1000) {
                    this.newarr1.push({value: element, unit: this.LangHandler('unityuan','元')});
                  } else if (element <= -100000000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 7) / 10,
                      unit: "亿",
                    });
                  } else if (element <= -100000000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 6) / 10,
                      unit: "亿",
                    });
                  } else if (element <= -10000000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 5) / 10,
                      unit: "亿",
                    });
                  } else if (element <= -1000000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 4) / 10,
                      unit: "亿",
                    });
                  } else if (element <= -100000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 3) / 10,
                      unit: "亿",
                    });
                  } else if (element <= -10000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 5),
                      unit: "万",
                    });
                  } else if (element <= -1000000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 4),
                      unit: "万",
                    });
                  } else if (element <= -100000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 3),
                      unit: "万",
                    });
                  } else if (element <= -10000) {
                    this.newarr1.push({
                      value: "-" + element.substring(1, 3) / 10,
                      unit: "万",
                    });
                  } else if (element <= -1000) {
                    this.newarr1.push({value: element, unit: this.LangHandler('unityuan','元')});
                  } else {
                    this.newarr1.push({value: element, unit: this.LangHandler('unityuan','元')});
                  }


                }
              }
            }

            this.newarr1.forEach((item, index) => {
              item["name"] = arr[index];
            });
            let lang = sessionStorage.getItem("locale") || 'CH'
            
            if(lang=='EN'){
              this.gridLeft = [0, 0, 0, 160];
              for(let item of this.detailInfo.chart1.seriesData){
                  if(item.name =='尖峰'){
                      item.name = 'spike'
                  }
                  if(item.name =='峰'){
                      item.name = 'peak'
                  }
                  if(item.name =='平'){
                      item.name = 'flat'
                  }
                  if(item.name =='谷'){
                      item.name = 'valley'
                  }
              }
            }
            this.initEchart();
          })
          .catch((wrong) => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
            currentValue == 0 || currentValue === null ||
            JSON.parse(currentValue) === null ||
            typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      let vm = this;
      var dom = this.$refs["chart1"];
      setTimeout(() => {
        if (!!dom) {
          dom.handleWindowResize();
        }
      }, 30);
      var {categoryData, seriesData} = this.detailInfo.chart1;
      var echarts = this.$echarts;
      var series = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          smooth: true,
          emphasis: {
            focus: "series",
          },

          symbol: "circle",
        };

        series.push(obj);
      });
      //console.log(seriesData);
      let option = {};
      if (this.searchObj.TYPE === "按天") {
        option = {
          animation: true,
          layoutAnimation: false,
          grid: {
            left: "2%",
            right: "3%",
            bottom: "7%",
            top: "15%",
            containLabel: true,
          },
          legend: {
            right: 0,
            top: "0%",
            //orient: "vertical",
            icon: "rect", //图例形状
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#1d2129",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: function (params) {
              var str = "";
              if (
                  (params[0].value !== undefined ||
                      params[1].value !== undefined ||
                      params[2].value !== undefined ||
                      params[3].value !== undefined) &&
                  (params[0].value !== 0 ||
                      params[1].value !== 0 ||
                      params[2].value !== 0 ||
                      params[3].value !== 0)
              ) {
                str += params[0].name;
              }
              const map = params.map((item, index) => {
                if (
                    item.value != "0" &&
                    item.value != 0 &&
                    item.value !== undefined
                ) {
                  str +=
                      "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                      "<span>" +
                      item.seriesName +
                      " : </span>" +
                      "<span>" +
                      item.value +
                      "</span>" +
                      "</div>";
                  return item;
                }
              });
              params = map;
              return str;
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
              height: 20,
              bottom: 0,
            },
            {
              start: 0,
              end: 100,
              height: 20,
              bottom: 10,
            },
          ],
          xAxis: {
            type: "category",
            data: categoryData,
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
          },
          yAxis: {
            name: this.LangHandler('electricityyuan','电费（元）'),
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },

          // 控住柱状图样式
          // series:series,
          series: [
            {
              type: "line",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: this.commonLangHandler('powerBox19_spike',seriesData[0].name, this.getZEdata),
              data: seriesData[0].value,
              smooth: 0.2,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CEE8E2",
                  },
                  {
                    offset: 1,
                    color: "#CEE8E2",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: this.commonLangHandler('powerBox19_peak',seriesData[1].name, this.getZEdata),
              data: seriesData[1].value,
              // smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CCD9FF",
                  },
                  {
                    offset: 1,
                    color: "#CCD9FF",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: this.commonLangHandler('powerBox19_common',seriesData[2].name, this.getZEdata),
              data: seriesData[2].value,
              // smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#FCE8C8",
                  },
                  {
                    offset: 1,
                    color: "#FCE8C8",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: this.commonLangHandler('powerBox19_valley',seriesData[3].name, this.getZEdata),
              data: seriesData[3].value,
              smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#D9D9D9",
                  },
                  {
                    offset: 1,
                    color: "#D9D9D9",
                  },
                ]),
              },
            },
          ],
          color: this.colorList,
        };
      } else {
        option = {
          animation: true,
          layoutAnimation: false,
          grid: {
            left: "2%",
            right: "3%",
            bottom: "7%",
            top: "15%",
            containLabel: true,
          },
          legend: {
            right: 0,
            top: "0%",
            //orient: "vertical",
            icon: "rect", //图例形状
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#1d2129",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: function (params) {
              var str = "";
              if (
                  (params[0].value !== undefined ||
                      params[1].value !== undefined ||
                      params[2].value !== undefined ||
                      params[3].value !== undefined) &&
                  (params[0].value !== 0 ||
                      params[1].value !== 0 ||
                      params[2].value !== 0 ||
                      params[3].value !== 0)
              ) {
                str += params[0].name;
              }
              let total = 0;
              params.forEach((item, index) => {
                if (item.value) {
                  total += Number(item.value);
                }
              });
              str +=
                  "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                  "<span>" +
                  "总计" +
                  " : </span>" +
                  "<span>" +
                  total.toFixed(2) +
                  "</span>" +
                  "</div>";

              const map = params.map((item, index) => {
                total += Number(item.value);
                if (
                    item.value != "0" &&
                    item.value != 0 &&
                    item.value !== undefined
                ) {
                  str +=
                      "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                      "<span>" +
                      item.seriesName +
                      " : </span>" +
                      "<span>" +
                      item.value +
                      "</span>" +
                      "</div>";
                  return item;
                }
              });
              params = map;
              return str;
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
              height: 20,
              bottom: 0,
            },
            {
              start: 0,
              end: 100,
              height: 20,
              bottom: 10,
            },
          ],
          xAxis: {
            type: "category",
            data: categoryData,
            boundaryGap: true,
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
          },
          yAxis: {
            name: this.LangHandler('electricityyuan','电费（元）'),
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },

          // 控住柱状图样式
          // series:series,
          series: [
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[0].name,
              data: seriesData[0].value,
              stack: "总量",
              barWidth: "20%",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CEE8E2",
                  },
                  {
                    offset: 1,
                    color: "#CEE8E2",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[1].name,
              data: seriesData[1].value,
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CCD9FF",
                  },
                  {
                    offset: 1,
                    color: "#CCD9FF",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[2].name,
              stack: "总量",
              data: seriesData[2].value,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#FCE8C8",
                  },
                  {
                    offset: 1,
                    color: "#FCE8C8",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[3].name,
              data: seriesData[3].value,
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#D9D9D9",
                  },
                  {
                    offset: 1,
                    color: "#D9D9D9",
                  },
                ]),
              },
            },
          ],
          color: this.colorList,
        };
      }

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.powerBox19 {
  .card-content1 {
    .content1-line {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;

      > div {
        background: #fafafa;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 10px;

        > span {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
          }

          &:nth-child(2) {
            font-size: 32px;
            font-weight: 500;
            color: #1d2129;
            line-height: 40px;

            font {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .card-content2 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
